 /**
  * =====================================
  * Main.js
  * =====================================
  * main file for js
  * =====================================
  */

var templateUrl  = $('body').data('directory-uri');


function detectSafari(){
  var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return is_safari;
}

function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
       return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

function initialLoad(){
  
  $('.lazy').Lazy({
    // your configuration goes here
    scrollDirection: 'vertical',
    effect: 'fadeIn',
    effect: "fadeIn",
    effectTime: 500,
    threshold: 0,
    chainable: true,
    visibleOnly: true,
    onError: function(element) {
        console.log('error loading ' + element.data('src'));
    }
  });

}



$( document ).ready(function() {

  /** Detect IE Remove WebP
   * ===================================== */
  // if(detectIE() || detectSafari()){
  //   $("body").children().each(function () {
  //     $(this).html( $(this).html().replace(/.webp/g,"") );
  //   });
  // }

   /** SLIK SLIDERS

  
  /** Barba JS
   * ===================================== */

  // Barba.Pjax.start();

  // Barba.Prefetch.init();

  // Barba.Dispatcher.on('transitionCompleted', function(currentStatus, oldStatus, container) {
    
  //   initialLoad();

  // });
  
  sliderLoader();
  initialLoad();
  /** LazyLoading
   * ===================================== */


//   document.addEventListener('lazybeforeunveil', function(e){
//   var bg = e.target.getAttribute('data-bg');
//   if(bg){
//       e.target.style.backgroundImage = 'url(' + bg + ')';
//   }
// });

  /** Function called
   * ===================================== */
  headerAnimate();

  /** Mobile Menu
   * ===================================== */
  $(document).on('click tap','.mobile-menu-ctrl',function(e){

    e.preventDefault();

    if($('body').hasClass('mobile-active')){
      $('body').removeClass('mobile-active').removeClass('overlay-open');
    }else{
      $('body').addClass('mobile-active').addClass('overlay-open');
    }

  });

  $('.mobile-menu-list .menu-item-has-children > a').append('<span class="sub-ctrl"><i class="fas fa-caret-right"></i></span>');
  $('.mobile-menu-list').on('click tap','.sub-ctrl',function(){

    if($(this).closest('li').hasClass('active')){
      $(this).closest('li').removeClass('active');
      $(this).closest('a').next('.sub-menu').slideUp();
    }else{
      $(this).closest('li').addClass('active');
      $(this).closest('a').next('.sub-menu').slideDown();
    }
    return false;
  });

  $(document).on('click tap','.gq-ctrl',function(){

    // if($('#get-quote').hasClass('active')){
    //   $('#get-quote').removeClass('active');
    //   $('body').removeClass('overlay-open');
    // }else{
    //   $('#get-quote').addClass('active');
    //   $('body').addClass('overlay-open');
    // }

    $('#modalQuote').modal('show');

    return false;
  });

  $(document).on('click tap','.scroll-to-div',function(){
    var divSelected = $(this).data('div');
    $('html, body').animate({
        scrollTop: $("#"+divSelected).offset().top - $('#header').outerHeight()
    }, 2000);
    return false;
  });


   $('.last-word-detect').each(function(index, element) {
      var heading = $(element);
      var word_array, last_word, first_part;

      word_array = heading.html().split(/\s+/); // split on spaces
      last_word = word_array.pop();             // pop the last word
      first_part = word_array.join(' ');        // rejoin the first words together

      heading.html([first_part, ' <span class="last-word">', last_word, '</span>'].join(''));
  });
  /** To top
   * ===================================== */
  $(".back-top").hide();
  $(function () {
      $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
              $('.back-top').fadeIn();
          } else {
              $('.back-top').fadeOut();
          }
      });
      $('.back-top,#search-menu').click(function () {
          $('body,html').animate({
              scrollTop: 0
          }, 800);
          return false;
      });
  });

  $('.back-top').mouseenter(function() {
      $(this).css({
          'opacity'    :'1',
          'transition' :'0.3s'
      });
  })
  .mouseleave(function() {
      $(this).css({
          'opacity'    :'0.5',
          'transition' :'0.3s'
      });
  });

  /** Mobile Menu
   * ===================================== */
    // $('.primary-menu').clone().appendTo('#sidepanel .menu');
    // $('#sidepanel .menu').find('.primary-menu').addClass('mobile-menu').removeClass('hidden');
    // $('#sidepanel .mobile-menu li').each(function() {
    //     if( $(this).hasClass('menu-item-has-children') ) {
    //         $(this).append('<span class="sub-ctrl-arrow sub-ctrl"><i class="fa fa-caret-down" aria-hidden="true"></i></span>');
    //     } else {
    //         $(this).append('<span class="sub-ctrl-arrow"><i class="fas fa-arrow-right"></i></span>');
    //     }
    // });
    // $('#sidepanel .mobile-menu .desktop-caret').detach();
    // $('#sidepanel').on('click','.sub-ctrl',function() {
    //     if( $(this).hasClass('active')) {
    //         $(this).removeClass('active').prev('.sub-menu').slideUp();
    //         $(this).find('.fa').removeClass('fa-caret-up').addClass('fa-caret-down');
    //     } else {
    //         $(this).addClass('active').prev('.sub-menu').slideDown();
    //         $(this).find('.fa').removeClass('fa-caret-down').addClass('fa-caret-up');
    //     }
    // });

  /** Window Load
   * ===================================== */
	$(window).on("load", function(){
     $(window).resize();
     $('.lazy').Lazy({ chainable: false });
	});

  /** Window on resize
   * =================================== */
  $(window).resize(function() {
     if(Modernizr.mq('(min-width: 992px)')){

       sliderUnloader();
     }else{
    
      //if(!$('.slider-projects-mobile').hasClass('slick-initialized')) {
        sliderLoader();
      
      //}
      
     }
  });

	// IOS INPUT BUG FIX
  (function iOS_CaretBug() {
     var ua = navigator.userAgent,
     scrollTopPosition,
     iOS = /iPad|iPhone|iPod/.test(ua),
     iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

     // ios 11 bug caret position
     if ( iOS && iOS11 ) {

         $(document.body).on('show.bs.modal', function(e) {
             if ( $(e.target).hasClass('modal') ) {
                 // Get scroll position before moving top
                 scrollTopPosition = $(document).scrollTop();

                 // Add CSS to body "position: fixed"
                 $("body").addClass("iosBugFixCaret");
             }
         });

         $(document.body).on('hide.bs.modal', function(e) {
             if ( $(e.target).hasClass('modal') ) {
                 // Remove CSS to body "position: fixed"
                 $("body").removeClass("iosBugFixCaret");

                 //Go back to initial position in document
                 $(document).scrollTop(scrollTopPosition);
             }
         });
     }
  })();



  /** Caroufredsel Slider
   * ===================================== */
	function caroufredsel_slider() {

		$(".cs-slider .slides").each(function(){
			var $this    = $(this);
			var ctrlPrev = $(this).closest(".cs-slider").find(".prev");
			var ctrlNext = $(this).closest(".cs-slider").find(".next");

			$this.carouFredSel({
				auto: {
					play: $this.data("auto"),
					timeoutDuration: $this.data("duration"),
				},
				start      : "random",
				width      : $this.data("cs-width"),
				circular   : $this.data("cs-circular"),
				infinite   : $this.data("cs-infinite"),
				height     : $this.data("cs-height"),
				responsive : $this.data("cs-responsive"),
				direction  : $this.data("cs-direction"),
				prev       : ctrlPrev,
				next       : ctrlNext,
				pagination : "."+$this.data("paginate"),
				scroll:{
					items: $this.data("item-scroll"),
					fx   : $this.data("effects"),
				},
				items:{
				  height : $this.data("item-height"),
				  width  : $this.data("item-width"),
				  visible: {
				    	min: $this.data("min"),
				    	max: $this.data("max")
				  },
				},
				swipe:true,
			});

			$this.touchwipe({
				wipeUp: function(e) {
				 	e.preventDefault();
				},
				wipeDown: function(e) {
				 	e.preventDefault();
				},
				wipeLeft: function(e) {
				 	$this.trigger("next", 1);
				},
				wipeRight: function(e) {
				 	$this.trigger("prev", 1);
				}
			});
		});
	}
});


/** Slick Sliders
 * ===================================== */
function sliderUnloader(){
  if($('.slider-projects-mobile').hasClass('slick-initialized')) {
    $('.slider-projects-mobile').slick('unslick');
  }
}
function sliderLoader(){

  if($('.slider-services').length && !$('.slider-services').hasClass('slick-initialized')){

    $('.slider-services').each(function(){

      var slidesMax = $(this).data('slides-max') ? $(this).data('slides-max') : 3; 

      $(this).slick({
        infinite: true,
        slidesToShow: slidesMax,
        slidesToScroll: slidesMax,
        arrows: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: slidesMax,
              slidesToScroll: slidesMax,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });

    });
 
  }

  if( $('.lb-slider').length && !$('.lb-slider').hasClass('slick-initialized')){
    var speed = $('.lb-slider').data('speed');
    $('.lb-slider').slick({
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: speed,
      speed: 800,
      slidesToShow: 1,
      arrows: false,
      swipe: false,
      fade: true,
      cssEase: 'linear'
    });
  }


  if( $('.tags-list').length && !$('.tags-list').hasClass('slick-initialized')){

    $('.tags-list').slick({
      arrows: false,
      infinite: true,
      autoplay: true,
      centerMode: true,
      autoplaySpeed: 1,
      speed: 3000,
      variableWidth: true,
      swipe: false
    });
  }

  if( $('.testimonials-list').length && !$('.testimonials-list').hasClass('slick-initialized')){
    $('.testimonials-list').slick({
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      speed: 800,
      slidesToShow: 1,
      adaptiveHeight: true,
      arrows: false,
    });
  }

  if( $('.logo-list').length && !$('.logo-list').hasClass('slick-initialized')){
    $('.logo-list').slick({
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
          }
        }
      ]
    });
  }

  if( $('.slider-projects-mobile').length && !$('.slider-projects-mobile').hasClass('slick-initialized')){
    $('.slider-projects-mobile').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll:1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }

  
}

/** Header Animation
 * ===================================== */
function headerAnimate() {


  window.onscroll = function() {myFunction()};

  // Get the header
  var header = document.getElementById("header");

  var sticky = header.offsetTop;

  function myFunction() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  } 
   
}

/** Animate Scrolling
 * ================================= */
function scrollAnimate() {

    $('a[href*=\\#]:not([href=\\#])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - $('#header').outerHeight()
                }, 1000);
            return false;
            }
        }
    });

}

/** Sidebar Affix
 * ===================================== */
function sidebarAffix() {

    var sidebar            = $('.default-sidebar'),
        innercontent       = $('.default-page-content'),
        sidebarHeight      = $('.default-sidebar').outerHeight(),
        innercontentHeight = $('.default-page-content').outerHeight(),
        footer_menu        = $('.footer-menu').outerHeight(),
        footer_contact     = $('.fp-contact').outerHeight(),
        footer_gallery     = $('.bondi-gallery').outerHeight(),
        footer_map         = $('.bondi-map').outerHeight(),
        footer_height      = $('#footer').outerHeight(),
        header_height      = $('.header-fix').outerHeight() + 10,
        taser_height       = $('.teaser').outerHeight(),
        bottom_spacing     = footer_height + footer_menu + footer_contact + footer_gallery + footer_map + 30;

    if ( Modernizr.mq( '(min-width: 992px)' ) ) {
        if( innercontentHeight < sidebarHeight ) {
            sidebar.unstick();
        } else {
            sidebar.sticky({topSpacing: header_height, bottomSpacing: bottom_spacing});
        }
    } else {
      sidebar.unstick();
    }

}

/** Offcanvas Menu
 * ===================================== */
function offcanvas_menu() {
    // $('#header').on('click', '.menu-bar', function() {
    //     $('#header .menu-bar').toggleClass('active');
    //     $('.offcanvas-menu').toggleClass('active');
    // });
}

/** Footer Menu
 * ===================================== */
function setFooterMenu() {
    if ( Modernizr.mq('(max-width: 768px)') ) {
        $('body .bondi-toggle-menu').unbind().on('click','.widget-title',function() {
            var $this = $(this),
                $item = $this.closest('.widget').find('h3').next('div');
            if($this.hasClass('item-is-active')) {
                $this.removeClass('item-is-active');
                $item.stop().slideUp();
            } else {
                $this.addClass('item-is-active');
                $item.stop().slideDown();
            }
        });
    } else {
        $('body .footer-menu').off( 'click','.widget-title' );
    }
}

/** Real Paralax
* ===================================== */
function parallax() {
    var parallax = document.querySelectorAll(".parallax"),
    speed = 0.0001;
    window.onscroll = function(){
    [].slice.call(parallax).forEach(function(el,i){
     var windowYOffset = window.pageYOffset,
        elBackgrounPos = "50% " + (windowYOffset * speed) + "px";
        el.style.backgroundPosition = elBackgrounPos;
        });
    };
}


/** BG Hover on Mouse Move
* ===================================== */
var lFollowX = 0,
    lFollowY = 0,
    x = 0,
    y = 0,
    friction = 1 / 30;

function moveBackground() {
  x += (lFollowX - x) * friction;
  y += (lFollowY - y) * friction;
  
  translate = 'translate(' + x + 'px, ' + y + 'px) scale(1.1)';

  $('.follow-mouse').css({
    '-webit-transform': translate,
    '-moz-transform': translate,
    'transform': translate
  });

  window.requestAnimationFrame(moveBackground);
}

$(window).on('mousemove click', function(e) {

  var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
  var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
  lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
  lFollowY = (10 * lMouseY) / 100;

});

moveBackground();